.other_schema_section {
	background-color: var(--grey-scale-3);

	min-height: 130px;
	padding: 8px;
}

h2.other_schema_title {
	margin: 0;
	text-transform: capitalize;
}
