.container {
	display: flex;
	flex-direction: column;
	grid-auto-rows: max-content;
	overflow: hidden;
	box-sizing: border-box;
	appearance: none;
	outline: none;
	min-width: 350px;
	border-radius: 5px;
	/* min-height: 200px; */
	transition: background-color 350ms ease;
	background-color: #f6f6f6;
	border: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 1em;
}

.container.empty {
	min-height: unset;
	background-color: unset;

	text-transform: uppercase;
}

.container ul {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(var(--columns, 1), 1fr);
	list-style: none;
	padding: 20px;
	margin: 0;
}

.container.scrollable ul {
	overflow-y: auto;
}

.container.placeholder {
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.5);
	background-color: transparent;
	border-style: dashed;
	border-color: rgba(0, 0, 0, 0.08);
}
.container.placeholder:hover {
	border-color: rgba(0, 0, 0, 0.15);
}
.container.hover {
	background-color: #ebebeb;
}
.container.unstyled {
	overflow: visible;
	background-color: transparent !important;
	border: none !important;
}
.container.horizontal {
	width: 100%;
}
.container.horizontal ul {
	grid-auto-flow: column;
}
.container.shadow {
	box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
}
.container:focus-visible {
	border-color: transparent;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
}
