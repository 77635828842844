.bullet {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: var(--info-color);
}

.bullet.bullet_middle {
	width: 8px;
	height: 8px;
}

.bullet.bullet_large {
	width: 12px;
	height: 12px;
}
