:root {
	--vertical-card-size: 6em;
}

.custom_scrollbar {
	scrollbar-color: var(--grey-scale-4) transparent;
	scrollbar-width: 1em;
}

.device_content {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;

	padding-top: 12px;
	padding-left: 12px;

	scrollbar-color: var(--grey-scale-4) transparent;
	scrollbar-width: 1em;
}

.vertical_group_list {
	overflow-x: auto;
}

.vertical_card {
	min-height: var(--vertical-card-size);
	max-height: var(--vertical-card-size);
	min-width: var(--vertical-card-size);
	max-width: var(--vertical-card-size);

	padding: 8px;
	background-color: var(--grey-scale-3);
	border-radius: 8px;
}

.vertical_card img {
	height: 40px;
	width: 40px;
}

.vertical_card .title {
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
}
