.card {
	width: 160px;
	min-width: 160px;
	overflow: hidden;
}

.card_cover {
	width: 100%;
	height: 90px;
	background-color: var(--grey-scale-2);
	overflow: hidden;
}

.card_cover img {
	object-fit: contain;
}
