.schema_component {
	background-color: var(--grey-scale-3);
	border-color: var(--grey-scale-4);

	min-width: 280px;
}

.schema_component.dragging {
	opacity: var(--dragging-opacity, 0.5);
}

.schema_component.error {
	border-color: var(--ant-error-color);
}

.template_representation_container {
	user-select: none;

	width: 260px;
}

.template_box_component {
	border: 1px solid var(--border-color);
	border-radius: 4px;

	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

	background: white;
	color: #3b424b;

	text-align: center;
	vertical-align: middle;

	min-width: 20px;
	min-height: 20px;
}

.template_box_component.template_box_component_circle {
	border-radius: 50%;
}

.template_box_component:hover {
	color: var(--ant-primary-color);
	border-color: var(--ant-primary-color);
	background: white;
}

/** Vertical Quick Access */
.template_vertical .template_box_component {
	height: 50px;
	width: 50px;
}

/** Scrollable Swimlane */
.template_banner_carousel .template_box_component {
	height: 50px;
	width: 100%;
}

/** Listing Swimlane */
.template_matrix_vendor_swimlane .template_box_component.small {
	height: 30px;
	width: 30px;
}

.template_matrix_vendor_swimlane .template_box_component.big {
	height: 80px;
	width: 80px;
}

/** BillBoard Banner */
.template_bill_board_banner {
	height: 80px;
	width: 100%;
}

/** Generic Swimlane */
.template_generic_vendor_swimlane .logo {
	height: 36px;
	width: 36px;
}
.template_generic_vendor_swimlane .list {
	overflow: hidden;
}
.template_generic_vendor_swimlane .list_item .template_box_component {
	min-height: 50px;
	min-width: 50px;
}

/** Hero Swimlane */
.template_hero_vendor_swimlane .template_box_component {
	height: 60px;
	width: 100%;
}

/** Trackable Orders & Profile Section */
.template_trackable_orders,
.template_profile_section {
	height: 100%;
	width: 100%;
	border-radius: 8px;
	padding: 12px;
	background-color: white;
	border: 1px solid var(--border-color);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.template_trackable_orders .template_box_component.icon {
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.template_trackable_orders .template_box_component.action {
	height: 24px;
	width: 70px;

	border-radius: 16px;
}

/** Vertical List */
.template_template_vertical_list .template_box_component {
	height: 40px;
	width: 100%;
}
