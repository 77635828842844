.home_background {
	position: relative;
	background: var(--layout-background);
}

.device_content {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
}

.position_indicator {
	min-width: 25px;
	max-width: 25px;
	text-align: center;
}
